import { SVGProps } from 'react';

const IconUserMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.1252 14.6667L19.7085 19.25M19.7085 14.6667L15.1252 19.25M11.0002 14.2083H6.87517C5.5959 14.2083 4.95626 14.2083 4.43578 14.3662C3.26391 14.7217 2.34687 15.6388 1.99138 16.8106C1.8335 17.3311 1.8335 17.9707 1.8335 19.25M13.2918 6.875C13.2918 9.15317 11.445 11 9.16683 11C6.88865 11 5.04183 9.15317 5.04183 6.875C5.04183 4.59683 6.88865 2.75 9.16683 2.75C11.445 2.75 13.2918 4.59683 13.2918 6.875Z'
      stroke='#22313E'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
export { IconUserMinus };
