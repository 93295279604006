import { SVGProps } from 'react';

const IconChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' {...props}>
    <path
      d='M16.2736 11.9147L9.96125 5.23963C9.81525 5.08512 9.62036 5 9.41255 5C9.20474 5 9.00985 5.08512 8.86385 5.23963L8.39899 5.73109C8.0965 6.05133 8.0965 6.57182 8.39899 6.89157L13.6996 12.4969L8.39311 18.1084C8.24711 18.2629 8.1665 18.4689 8.1665 18.6885C8.1665 18.9084 8.24711 19.1144 8.39311 19.269L8.85797 19.7604C9.00408 19.9149 9.19886 20 9.40667 20C9.61448 20 9.80937 19.9149 9.95536 19.7604L16.2736 13.0792C16.4199 12.9242 16.5003 12.7173 16.4998 12.4973C16.5003 12.2764 16.4199 12.0696 16.2736 11.9147Z'
      fill='currentColor'
    />
    <mask id='mask0_1320_227185' maskUnits='userSpaceOnUse' x='8' y='5' width='9' height='15'>
      <path
        d='M16.2736 11.9147L9.96125 5.23963C9.81525 5.08512 9.62036 5 9.41255 5C9.20474 5 9.00985 5.08512 8.86385 5.23963L8.39899 5.73109C8.0965 6.05133 8.0965 6.57182 8.39899 6.89157L13.6996 12.4969L8.39311 18.1084C8.24711 18.2629 8.1665 18.4689 8.1665 18.6885C8.1665 18.9084 8.24711 19.1144 8.39311 19.269L8.85797 19.7604C9.00408 19.9149 9.19886 20 9.40667 20C9.61448 20 9.80937 19.9149 9.95536 19.7604L16.2736 13.0792C16.4199 12.9242 16.5003 12.7173 16.4998 12.4973C16.5003 12.2764 16.4199 12.0696 16.2736 11.9147Z'
        fill='white'
      />
    </mask>
  </svg>
);
export { IconChevronRight };
